import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const News = () => (
  <Layout>
    <SEO title="News" />
    <section className="section bg-gray-100">
      <div className="container py-8">
        <div className="row justify-content-between">
          <div className="col col-md">
            <div className="card" style={{ width: "18rem" }}>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default News
